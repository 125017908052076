.root {
  --offset-top: calc(
    var(--space-xl) + var(--nav-height) + var(--banner-height)
  );
  --hero-illustration-overlap: min(20vh, 150px);
}

.root.themeDark {
  --hero-background: var(--color-background-inverted);
  --hero-illustration-background: var(--color-background-light);
  --hero-color: var(--color-text-on-color);
  --color-text-secondary: rgb(255 255 255 / 65%);
  --hero-border-color: rgba(255 255 255 / 7.5%);
}

.root.themeLight {
  --hero-background: var(--color-background-light);
  --hero-illustration-background: var(--color-background-inverted);
  --hero-color: var(--color-text-default);
  --hero-border-color: var(--color-border);
}

.headerContainer {
  position: relative;
  overflow: hidden;
  background-color: var(--hero-background);
  color: var(--hero-color);
}

.root.noBackground .headerContainer {
  background-color: transparent;
}

.gradient {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0.5;
    background: linear-gradient(
      270deg,
      transparent 0%,
      var(--hero-background) 100%
    );
  }

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.backgroundIllustration {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.root.flipGradient .gradient {
  transform: rotateY(180deg);
}

.headline {
  margin-bottom: var(--space-m);
  font-size: fluid(44px, 72px);
}

.root.headingSizeSmall .headline {
  margin-bottom: var(--space-m);
  padding-right: var(--space-m);
  font-size: fluid(32px, 52px);
}

.root.headingSizeXLarge .headline {
  padding-right: var(--space-m);
  font-size: fluid(48px, 88px);
}

.headerInner {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--offset-top) var(--layout-gutter) var(--space-xl)
    var(--layout-gutter);
}

.root.hasMediaOverlap .headerInner {
  padding-bottom: calc(var(--hero-illustration-overlap) + var(--space-xl));
}

.mediaOverlap {
  --color-text-secondary: var(--base-color-grey-600);

  position: relative;
  z-index: 2;
  margin: calc(-1 * var(--hero-illustration-overlap)) var(--layout-gutter) 0
    var(--layout-gutter);
  overflow: hidden;
  border-radius: var(--border-radius-default);
  background-color: var(--hero-illustration-background);
  aspect-ratio: 1.25 / 1;
}

.preprocessMedia > * {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: translate(-50%, -50%);
}

.imageWrapper > * {
  object-fit: cover;
}

.headerMedia {
  position: relative;
}

.children {
  position: relative;
  z-index: 1;
  margin-top: var(--space-l);
}

.root.noChildren .children {
  padding-bottom: 0;
}

.intro {
  max-width: 45ch;
  font-family: var(--font-family-headline);
  font-size: fluid(20px, 24px);
  letter-spacing: -0.005em;
  line-height: 1.3;
}

.list {
  margin-top: var(--space-l);
}

.clientLogos {
  padding-top: var(--space-l);
  border-top: 1px solid var(--hero-border-color);
  margin-bottom: var(--space-2xl);

  & h3 {
    margin-left: var(--layout-gutter);
    margin-bottom: var(--space-xl);
  }
}

@media (--medium) {
  .root {
    --hero-illustration-overlap: 300px;

    position: relative;
  }

  .headerInner {
    display: grid;
    grid-gap: var(--space-2xl);
    grid-template-columns: repeat(2, 1fr);
  }

  .root.verticalAlignCenter .headerInner {
    align-items: center;
  }

  .headerMedia,
  .children {
    position: relative;
    margin-bottom: var(--space-xl-2xl);
  }

  .headline {
    max-width: 20ch;
    margin-bottom: var(--space-l);
    padding-right: var(--space-3xl);
  }

  .root.noChildren .headerInner {
    display: block;
  }

  .root.noChildren .headline {
    max-width: 25ch;
  }

  .root.noChildren.headingSizeXLarge .headline {
    max-width: 20ch;
  }

  .children {
    margin-top: 0;
  }

  .root.hasEyebrow .children {
    margin-top: var(--space-l);
  }

  .root.verticalAlignCenter .children {
    margin-top: var(--space-l);
    margin-bottom: var(--space-l);
  }

  .media {
    aspect-ratio: var(--hero-media-aspect-ratio, unset);
  }

  .clientLogos {
    margin-bottom: var(--space-xl-2xl);
  }
}

@media (--large) {
  .clientLogos {
    margin-bottom: var(--space-2xl);
  }
}
