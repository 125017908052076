@media (--small) {
  .wide {
    max-width: 70ch;
  }

  .medium {
    max-width: 50ch;
  }

  .narrow {
    max-width: 35ch;
  }

  .ultraNarrow {
    max-width: 24ch;
  }

  /* More consistent measures over long form content e.g. article */
  .article {
    max-width: var(--long-form-article-width);
  }
}
