.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-xs);
  margin-top: var(--space-l-xl);
}

.action {
  width: 100%;
}

@media (--small) {
  .root {
    flex-direction: row;
  }

  .action {
    width: unset;
    min-width: 200px;
  }
}
